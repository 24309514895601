import CartManagerCounterUpdaterInterface from "./CartManagerCounterUpdaterInterface";

class CartManagerCounterUpdatersContext {
    private updaters: CartManagerCounterUpdaterInterface[] = [];
    addCounterUpdater(counterUpdater: CartManagerCounterUpdaterInterface) {
        this.updaters.push(counterUpdater);
    }
    async updateCount(count: number): Promise<boolean> {
        for (const updater of this.updaters) {
            const result = await updater.updateCount(count);
            if (result) return result;
        }
        return false;
    }
}

export default CartManagerCounterUpdatersContext;
