import TranslationsApiService from "@/services/TranslationsApiService/TranslationsApiService";
import { LocalizationInfoProviderInterface } from "@lib/LocalizationInfoProvider/LocalizationInfoProviderInterface";
import { singleton } from "tsyringe";

import { I18nApiLoader } from "./loaders/I18nApiLoader/I18nApiLoader";
import { I18nScriptTagLoader } from "./loaders/I18nScriptTagLoader/I18nScriptTagLoader";

@singleton()
class I18nCriticalLoaderFactory {
    constructor(
        private readonly localizationInfoProvider: LocalizationInfoProviderInterface,
        private readonly translationsApiService: TranslationsApiService,
        private readonly isEmbeddedMode: boolean
    ) {}

    getLoader(namespace: string) {
        if (this.isEmbeddedMode) {
            return new I18nScriptTagLoader(
                `${namespace}.${this.localizationInfoProvider.getGwI18nLocale()}`
            );
        } else {
            return new I18nApiLoader(
                namespace,
                this.localizationInfoProvider.getGwI18nLocale(),
                this.translationsApiService
            );
        }
    }
}

export default I18nCriticalLoaderFactory;
