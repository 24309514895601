import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import CartListenerInterface from "./CartListenerInterface";
import CartListenerStrategyInterface from "./CartListenerStrategyInterface";

class CartListener implements CartListenerInterface {
    strategies: CartListenerStrategyInterface[] = [];
    constructor(private readonly logger: LoggerInterface) {}
    listen() {
        this.logger.debug("CartListener.listen start cart listening");
        return from(this.strategies).pipe(
            mergeMap((strategy) => strategy.listen())
        );
    }
    register(strategy: CartListenerStrategyInterface) {
        this.logger.debug("CartListener.register new strategy", {
            strategy,
        });
        this.strategies.push(strategy);
    }
}

export default CartListener;
