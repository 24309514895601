import { App } from "@/constants";
import {
    array,
    boolean,
    enums,
    nullable,
    number,
    object,
    string,
} from "superstruct";

const InitOptionsSchema = object({
    enabledApps: array(enums(Object.values(App))),
    customerId: nullable(number()),
    customerEmail: nullable(string()),
    isB2bCustomer: boolean(),
    moneyFormat: string(),
    moneyWithCurrencyFormat: string(),
    shopCurrency: string(),
    storefrontApiAccessToken: nullable(string()),
    countryIsoCode: string(),
    languageIsoCode: string(),
    currencyIsoCode: string(),
    gwI18nLocale: string(),
});

export default InitOptionsSchema;
