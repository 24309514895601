import { STATUS } from "@/constants/statuses";
import ShopifyCartApiService from "@modules/cart/api_services/CartService/ShopifyCartApiService";
import { CartStore } from "@modules/cart/stores/CartStore";
import { Observable, lastValueFrom } from "rxjs";
import { tap } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import CartEntity from "../entities/CartEntity";
import CartManagerCounterUpdatersContext from "../utils/CartManager/CartManagerCounterUpdatersContext";
import CartManagerItemsUpdatersContext from "../utils/CartManager/CartManagerItemsUpdatersContext";

export class CartService {
    constructor(
        private readonly cartApiService: ShopifyCartApiService,
        private readonly logger: LoggerInterface,
        private readonly counterUpdatersContext: CartManagerCounterUpdatersContext,
        private readonly itemsUpdatersContext: CartManagerItemsUpdatersContext,
        private readonly cartStore: CartStore
    ) {}
    public getCart(): Observable<CartEntity> {
        this.cartStore.update((state) => ({
            ...state,
            status:
                state.status === STATUS.VIRGIN
                    ? STATUS.INITIAL_LOADING
                    : STATUS.LOADING,
        }));
        return this.cartApiService.getCart().pipe(
            tap((cart) => {
                this.cartStore.update((state) => ({
                    ...state,
                    items: cart.items,
                    editItemStatuses: {
                        ...cart.items.reduce((acc, item) => {
                            return {
                                ...acc,
                                [item.variant_id]: STATUS.READY,
                            };
                        }, {}),
                    },
                    status: STATUS.READY,
                }));
            })
        );
    }
    async addToCart(
        items: { variantID: number; quantity: number }[]
    ): Promise<CartEntity> {
        this.cartStore.update((state) => ({
            ...state,
            editItemStatuses: {
                ...state.editItemStatuses,
                ...items.reduce((acc, item) => {
                    return {
                        ...acc,
                        [item.variantID]: STATUS.LOADING,
                    };
                }, {}),
            },
        }));
        await lastValueFrom(this.cartApiService.addToCart(items)).then(() => {
            this.cartStore.update((state) => ({
                ...state,
                editItemStatuses: {
                    ...state.editItemStatuses,
                    ...items.reduce((acc, item) => {
                        return {
                            ...acc,
                            [item.variantID]: STATUS.READY,
                        };
                    }, {}),
                },
            }));
        });
        return await this.applyUpdaters();
    }
    async updateCart(
        updates: { variantID: number; quantity: number }[]
    ): Promise<CartEntity> {
        await lastValueFrom(this.cartApiService.updateCart(updates));
        return await this.applyUpdaters();
    }
    async applyUpdaters(): Promise<CartEntity> {
        this.logger.debug("ShopifyCartManager.applyUpdaters starts");
        // TODO: Добавить optimistic поведение
        const cart = await lastValueFrom(this.getCart());
        const [counterUpdaterResult, itemsUpdaterResult] =
            await Promise.allSettled([
                this.counterUpdatersContext.updateCount(cart.item_count),
                this.itemsUpdatersContext.updateItems(cart),
            ]);
        this.logger.debug("ShopifyCartManager.applyUpdaters finish", {
            counterUpdaterStatus: counterUpdaterResult,
            itemsUpdaterStatus: itemsUpdaterResult,
        });

        return cart;
    }
}
