import { Observable, fromEvent, of } from "rxjs";
import { map, tap } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import CartListenerStrategyInterface from "./CartListenerStrategyInterface";
import { AddToCartEvent } from "./types";

class SendFormListenerStrategy implements CartListenerStrategyInterface {
    constructor(private readonly logger: LoggerInterface) {}
    listen(): Observable<AddToCartEvent> {
        this.logger.debug("SendFormListenerStrategy start listening");
        const form: HTMLFormElement | null = document.querySelector(
            "[action='/cart/add']"
        );
        this.logger.debug("SendFormListenerStrategy form found");
        if (form) {
            return fromEvent(form, "submit").pipe(
                tap((event) => {
                    this.logger.debug(
                        "SendFormListenerStrategy new submit event",
                        {
                            event,
                        }
                    );
                }),
                map((): AddToCartEvent => {
                    const e = {} as AddToCartEvent;
                    for (const element of Array.from(form.elements)) {
                        if (element instanceof HTMLInputElement) {
                            switch (element?.name) {
                                case "id":
                                    e.variantId = parseInt(element.value);
                                    break;
                            }
                        }
                    }
                    return e;
                })
            );
        }
        return of();
    }
}

export default SendFormListenerStrategy;
