import { CURRENCY_PLACEHOLDER_REGEX } from "@/constants";

import { FORMATS } from "../constants";

class MoneyFormatter {
    constructor(
        private readonly money_format: string,
        private readonly money_with_currency_format: string
    ) {}
    private f(format_string: string, money: number): string {
        const match = format_string.match(CURRENCY_PLACEHOLDER_REGEX);
        if (!match) {
            throw new Error("incorrect format string(without placeholder)");
        }
        const format = match[1];
        if (Object.keys(FORMATS).includes(format)) {
            const value = FORMATS[format as keyof typeof FORMATS](money);
            return format_string.replace(CURRENCY_PLACEHOLDER_REGEX, value);
        } else {
            throw new Error("unknown format");
        }
    }
    format(money: number): string {
        return this.f(this.money_format, money);
    }
    format_with_currency(money: number): string {
        return this.f(this.money_with_currency_format, money);
    }
}

export default MoneyFormatter;
