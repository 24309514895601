import { InjectionToken } from "tsyringe";

import { ProductApiServiceInterface } from "./api_services/ProductApiServiceInterface";
import { ProductsApiServiceInterface } from "./api_services/ProductsApiServiceInterface";
import { ProductsLiquidApiServiceInterface } from "./api_services/ProductsLiquidApiServiceInterface";
import { ProductsQuery } from "./queries/ProductsQuery";
import { ProductsWithQuantityRulesQuery } from "./queries/ProductsWithQuantityRulesQuery";
import { ProductsService } from "./services/ProductsService";
import { ProductsStore } from "./stores/ProductsStore";
import { ProductsWithQuantityRulesStore } from "./stores/ProductsWithQuantityRulesStore";

export const productApiServiceToken = Symbol(
    "productsApiService"
) as InjectionToken<ProductApiServiceInterface>;

export const productsApiServiceToken = Symbol(
    "productsApiService"
) as InjectionToken<ProductsApiServiceInterface>;

export const productLiquidApiServiceToken = Symbol(
    "productLiquidApiService"
) as InjectionToken<ProductsLiquidApiServiceInterface>;

export const productsStoreToken = Symbol(
    "productsStore"
) as InjectionToken<ProductsStore>;

export const productsWithQuantityRulesStoreToken = Symbol(
    "productsWithQuantityRulesStore"
) as InjectionToken<ProductsWithQuantityRulesStore>;

export const productsServiceToken = Symbol(
    "productsService"
) as InjectionToken<ProductsService>;

export const productsQueryToken = Symbol(
    "productsQuery"
) as InjectionToken<ProductsQuery>;

export const productsWithQuantityRulesQueryToken = Symbol(
    "productsWithQuantityRulesQuery"
) as InjectionToken<ProductsWithQuantityRulesQuery>;
