import { QueryEntity } from "@datorama/akita";
import { injectable } from "tsyringe";

import ReviewsStoreBase, { ReviewsStateBase } from "../stores/ReviewsStoreBase";

@injectable()
class ReviewsQueryBase extends QueryEntity<ReviewsStateBase> {
    constructor(protected store: ReviewsStoreBase) {
        super(store);
    }

    active$ = this.selectActive();
    all$ = this.selectAll();
    paginationMeta$ = this.select((v) => v.paginationMeta);
    status$ = this.select((v) => v.status);
    error$ = this.selectError();
    first$ = this.selectFirst();
}

export default ReviewsQueryBase;
