import { nullable, number, object, string } from "superstruct";

const DiscountSchema = object({
    type: string(),
    key: string(),
    title: string(),
    description: nullable(string()),
    value: string(),
    created_at: string(),
    value_type: string(),
    allocation_method: string(),
    target_selection: string(),
    target_type: string(),
    total_allocated_amount: number(),
});

export default DiscountSchema;
