import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";

import Sdk from "../Sdk";
import { SdkRegisteredModuleInterface } from "../SdkRegisteredModuleInterface";
import CommonSdkEvents from "./CommonSdkEvents";
import CommonSdkMethods from "./CommonSdkMethods";

class CommonSdk implements SdkRegisteredModuleInterface {
    public readonly events = new CommonSdkEvents();

    public readonly methods: CommonSdkMethods;

    constructor(
        private readonly _productIdProvider: ProductIdFromSdkProvider,
        private readonly _variantIdProvider: ProductVariantIdFromSdkProvider
    ) {
        this.methods = new CommonSdkMethods(
            this._productIdProvider,
            this._variantIdProvider
        );
    }

    public register(sdk: Sdk & { common?: CommonSdk }) {
        sdk.common = this;
    }
}

export default CommonSdk;
