import { GW_ADDITIONAL_INFO_SELECTOR } from "@/constants";
import { APP_NAMES } from "@/constants/app-names";
import { globalLoggerToken } from "@/tokens";
import { getJsonFromScript } from "@lib/utils";
import { of } from "rxjs";
import { create } from "superstruct";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { BrandingOptionsEntity } from "../entities/BrandingOptionsEntity";
import { AdditionalInfoBrandingSchema } from "../schemas/BrandingSchemas";
import BrandingProviderInterface from "./BrandingProviderInterface";

@singleton()
class BrandingFromJsonProvider implements BrandingProviderInterface {
    private brandingOptions: BrandingOptionsEntity | null = null;

    constructor(
        @inject(globalLoggerToken)
        private readonly logger: LoggerInterface
    ) {
        const additionalInfoData = getJsonFromScript(
            GW_ADDITIONAL_INFO_SELECTOR
        );

        const additionalInfo = create(
            additionalInfoData,
            AdditionalInfoBrandingSchema
        );
        this.brandingOptions = additionalInfo.GW_BRANDING_OPTIONS;

        this.logger.info("branding provider is ready", {
            brandingOptions: this.brandingOptions,
        });
    }

    public selectWishlistBrandingOptions() {
        return of(
            this.brandingOptions && this.brandingOptions[APP_NAMES.WISHLIST]
        );
    }

    public selectReviewsBrandingOptions() {
        if (!this.brandingOptions) return of(null);
        const reviewsOptions = this.brandingOptions[APP_NAMES.REVIEWS];
        return of(reviewsOptions);
    }

    public selectRewardsBrandingOptions() {
        return of(
            this.brandingOptions && this.brandingOptions[APP_NAMES.REWARDS]
        );
    }
}

export default BrandingFromJsonProvider;
