import {
    array,
    boolean,
    enums,
    nullable,
    number,
    object,
    optional,
    string,
} from "superstruct";

import { CUSTOM_FORM_TYPE } from "../constants";
import CustomFormOptionSchema from "./CustomFormOptionSchema";

const CustomFormQuestionSchema = object({
    id: number(),
    formId: number(),
    questionType: enums(["customer_related", "product_related"]),
    answerType: enums(Object.values(CUSTOM_FORM_TYPE)),
    displayName: string(),
    question: string(),
    placeholder: nullable(string()),
    required: boolean(),
    options: optional(array(CustomFormOptionSchema)),
    order: number(),
});

export default CustomFormQuestionSchema;
