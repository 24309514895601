import { COMMON_SDK_EVENTS } from "./constants";

class CommonSdkEvents implements Record<COMMON_SDK_EVENTS, COMMON_SDK_EVENTS> {
    public readonly [COMMON_SDK_EVENTS.WIDGET_CLOSED] =
        COMMON_SDK_EVENTS.WIDGET_CLOSED;

    public readonly [COMMON_SDK_EVENTS.WIDGET_INJECTED] =
        COMMON_SDK_EVENTS.WIDGET_INJECTED;

    public readonly [COMMON_SDK_EVENTS.WIDGET_OPENED] =
        COMMON_SDK_EVENTS.WIDGET_OPENED;
}

export default CommonSdkEvents;
