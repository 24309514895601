import { DRAWER_POSITION } from "@/constants/drawer";
import { boolean, enums, literal, object, string, union } from "superstruct";

const ReviewsBrandingSchema = object({
    reviewsThemeSettings: object({
        primaryColor: string(),
        secondaryColor: string(),
    }),
    reviewsIconSettings: object({
        starColor: string(),
    }),
    reviewsWidgetsSettings: object({
        buttonsColor: union([
            literal("primaryColor"),
            literal("secondaryColor"),
        ]),
        reviewerNameDisplayFormat: union([
            literal("FN"),
            literal("SN"),
            literal("SLN"),
            literal("AN"),
        ]),
    }),
    reviewsDrawerSettings: object({
        isShowDrawer: boolean(),
        drawerPosition: enums(Object.values(DRAWER_POSITION)),
        launcherBackgroundColor: union([
            literal("primaryColor"),
            literal("secondaryColor"),
        ]),
        launcherFontColor: string(),
        launcherIconBackgroundColor: string(),
    }),
});

export default ReviewsBrandingSchema;
