const formatWithDelimiters =
    (precision = 2, thousands = ",", decimal = ".") =>
    (number: number): string => {
        const price = number.toFixed(precision);

        const parts = price.split(".");
        const ceils = parts[0].replace(
            /(\d)(?=(\d\d\d)+(?!\d))/g,
            "$1" + thousands
        );
        const cents = parts[1] ? decimal + parts[1] : "";

        return ceils + cents;
    };

export const FORMATS = {
    amount: formatWithDelimiters(2),
    amount_no_decimals: formatWithDelimiters(0),
    amount_with_comma_separator: formatWithDelimiters(2, ".", ","),
    amount_no_decimals_with_comma_separator: formatWithDelimiters(0, ".", ","),
    amount_with_apostrophe_separator: formatWithDelimiters(2, "'", "."),
};
