export enum COMMON_SDK_EVENTS {
    WIDGET_INJECTED = "widgetInjected",
    WIDGET_OPENED = "widgetOpened",
    WIDGET_CLOSED = "widgetClosed",
}

export enum COMMON_SDK_METHODS {
    SET_GLOBAL_PRODUCT_ID = "setGlobalProductId",
    SET_GLOBAL_VARIANT_ID = "setGlobalVariantId",
}

export const COMMON_SDK_NAME = "CommonSdk";
