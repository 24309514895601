import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { BehaviorSubject, Observable, lastValueFrom } from "rxjs";
import { filter, map, tap } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

const RATES_REGEXP = /rates:.+(\{.+\})/gim;
export const getRatesFromJsText = (
    jsScript: string
): { [key: string]: number } => {
    const match = RATES_REGEXP.exec(jsScript);
    if (!match || !match[1]) {
        throw new Error("Cannot parse curencies.js script and eject rates");
    }
    return JSON.parse(match[1]) as { [key: string]: number };
};

class GwCurrency {
    public rates = new BehaviorSubject<{ [key: string]: number } | null>(null);
    constructor(
        private readonly logger: LoggerInterface,
        private readonly apiClient: ApiClientInterface
    ) {}
    loadRates() {
        void lastValueFrom(
            this.apiClient
                .get({
                    url: "/services/javascripts/currencies.js",
                    responseType: "text",
                })
                .pipe(
                    map((response) => response.body),
                    filter(
                        (jsText): jsText is string => typeof jsText === "string"
                    ),
                    map((jsText) => getRatesFromJsText(jsText)),
                    tap((rates) => {
                        this.logger.debug("GwCurrency rates are loaded", {
                            rates,
                        });
                    })
                )
        ).then((v) => {
            this.rates.next(v);
        });
    }
    convert(amount: number, from: string, to: string): Observable<number> {
        return this.rates.pipe(
            filter((rates): rates is { [key: string]: number } => !!rates),
            map((rates) => {
                return (amount * rates[from]) / rates[to];
            })
        );
    }
}

export default GwCurrency;
