import I18nLoaderException from "@lib/GwI18n/I18nLoaderException";
import I18nLoaderInterface from "@lib/GwI18n/I18nLoaderInterface";
import { create } from "superstruct";

import { I18nScriptTagRecordsSchema } from "./schemas";

export class I18nScriptTagLoader implements I18nLoaderInterface {
    constructor(private readonly id: string) {}

    async load() {
        const scriptTag = document.getElementById(this.id);

        let rawData;
        if (!scriptTag) {
            throw new I18nLoaderException("script tag not found", {
                scriptTagSelector: this.id,
            });
        }
        if (!scriptTag?.innerHTML) {
            throw new I18nLoaderException("empty translations script tag");
        }
        try {
            rawData = JSON.parse(scriptTag.innerHTML) as unknown;
        } catch (error: unknown) {
            throw new I18nLoaderException("invalid json string");
        }
        try {
            const records = create(rawData, I18nScriptTagRecordsSchema);
            return Promise.resolve(records);
        } catch (error: unknown) {
            throw new I18nLoaderException("validation error", {
                validationError: error,
            });
        }
    }
}
