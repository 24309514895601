import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { InjectionToken } from "tsyringe";

import ReviewsSettingsQuery from "./queries/ReviewsSettingsQuery";
import QuestionsFormModalManager from "./widgets/questions_form_modal_widget/managers/QuestionsFormModalWidgetManager";
import ReviewFormModalManager from "./widgets/review_form_modal_widget/managers/ReviewsFormModalManager";
import ReviewsGalleryManager from "./widgets/reviews_gallery_widget/ReviewsGalleryManager";
import ReviewsQuery from "./widgets/reviews_product_page_widget/queries/ReviewsQuery";
import ReviewsService from "./widgets/reviews_product_page_widget/services/ReviewsService";

export const ReviewsApplicationConfigToken = Symbol(
    "ReviewsApplicationConfigToken"
);

export const ReviewsApplicationReviewsServiceToken = Symbol(
    "ReviewsApplicationReviewsServiceToken"
) as InjectionToken<ReviewsService>;

export const ReviewsApplicationReviewsQueryToken = Symbol(
    "ReviewsApplicationReviewsQueryToken"
) as InjectionToken<ReviewsQuery>;

export const reviewsApplicationLoggerToken = Symbol(
    "ReviewsApplicationLoggerToken"
);

export const reviewsAuthApiClientToken = Symbol(
    "reviewsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const reviewsTranslationsAuthApiClientToken = Symbol(
    "reviewsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const LeaveReviewPageApplicationConfigToken = Symbol(
    "LeaveReviewPageApplicationConfigToken"
);

export const ReviewsFormModalManagerToken = Symbol(
    "ReviewsFormModalManagerToken"
) as InjectionToken<ReviewFormModalManager>;

export const QuestionsFormModalManagerToken = Symbol(
    "QuestionsFormModalManagerToken"
) as InjectionToken<QuestionsFormModalManager>;
