import { literal, nullable, object, string, union } from "superstruct";

const BrandingThemeColorSchema = union([
    literal("primaryColor"),
    literal("secondaryColor"),
]);

const BrandingFontColorSchema = union([literal("#ffffff"), literal("#1a1a1a")]);

const RewardsBrandingSchema = object({
    rewardsPopUpWidget: object({
        programName: string(),
        theme: object({
            primaryColor: string(),
            secondaryColor: string(),
        }),
        banner: object({
            themeColor: BrandingThemeColorSchema,
            fontColor: BrandingFontColorSchema,
            image: nullable(string()),
        }),
        buttons: object({
            themeColor: BrandingThemeColorSchema,
            fontColor: BrandingFontColorSchema,
        }),
        content: object({
            iconsThemeColor: BrandingThemeColorSchema,
            linksThemeColor: BrandingThemeColorSchema,
        }),
        launcher: object({
            themeColor: BrandingThemeColorSchema,
            fontColor: BrandingFontColorSchema,
            widgetPosition: union([literal("BR"), literal("BL")]),
        }),
    }),
});

export default RewardsBrandingSchema;
