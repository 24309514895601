import MinilogLogger from "@lib/Logger";
import { BehaviorSubject } from "rxjs";

type OpenReviewFormModal = {
    productId: number;
};

export default class QuestionsFormModalManager {
    public productId$ = new BehaviorSubject<number | undefined>(undefined);
    public showModal$ = new BehaviorSubject<boolean>(false);

    constructor(private readonly logger: MinilogLogger) {
        logger.info("QuestionsFormModalManager is initialized.");
    }

    openFormModal({ productId }: OpenReviewFormModal) {
        this.logger.info("Open questions modal with params: ", {
            productId,
        });

        if (productId) {
            this.productId$.next(productId);
        }
        this.showModal$.next(true);
    }

    closeFormModal() {
        this.showModal$.next(false);
    }

    resetFormModal() {
        this.productId$.next(undefined);
    }
}
