import { create } from "superstruct";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { loginApplicationLoggerToken } from "../../apps/login/tokens";
import CustomerInfoEntity from "./CustomerInfoEntity";
import { CustomerInfoProviderInterface } from "./CustomerInfoProviderInterface";
import { CustomerInfoSchema } from "./CustomerInfoSchema";

@singleton()
class CustomerInfoFromStaticProvider implements CustomerInfoProviderInterface {
    private customerInfo: CustomerInfoEntity | null = null;

    constructor(
        @inject(loginApplicationLoggerToken)
        private readonly logger: LoggerInterface,
        customerInfo: CustomerInfoEntity
    ) {
        this.customerInfo = create(customerInfo, CustomerInfoSchema);
    }

    readData(): void {
        this.logger.info("customer info provider is ready", {
            customerInfo: this.customerInfo,
        });
    }

    getEmail(): string | null {
        return this.customerInfo?.email || null;
    }

    getId(): number | null {
        return this.customerInfo?.id || null;
    }

    getIsB2b(): boolean {
        return this.customerInfo?.isB2b || false;
    }
}

export default CustomerInfoFromStaticProvider;
