import { BehaviorSubject } from "rxjs";
import { singleton } from "tsyringe";

@singleton()
class RewardsPopUpWidgetLauncherManager {
    public isPopUpWidgetOpen$ = new BehaviorSubject<boolean>(false);

    public rewardsPopUpWidgetShow(): void {
        this.isPopUpWidgetOpen$.next(true);
    }

    public rewardsPopUpWidgetHide(): void {
        this.isPopUpWidgetOpen$.next(false);
    }

    public rewardsPopUpWidgetToggle(): void {
        const isPopUpWidgetOpen = this.isPopUpWidgetOpen$.getValue();

        this.isPopUpWidgetOpen$.next(!isPopUpWidgetOpen);
    }
}

export default RewardsPopUpWidgetLauncherManager;
