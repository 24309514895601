import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map, skip } from "rxjs/operators";

import {
    CallbackFunction,
    CallbackParams,
    Callbacks,
    Event,
    Modal,
    ModalParams,
    ModalsList,
    OpenModalsList,
    Subscribers,
} from "./types";

const ALLOWED_EVENTS: Event[] = [
    "afterOpen",
    "beforeOpen",
    "afterClose",
    "beforeClose",
    "afterRegister",
];

export class ModalManager {
    modals: ModalsList = [];
    _openedModals = new BehaviorSubject<OpenModalsList>([]);
    subscribers: Subscribers = {};
    //
    callbacks: Callbacks = {
        afterOpen: [],
        beforeOpen: [],
        afterClose: [],
        beforeClose: [],
        afterRegister: [],
    };
    // meta
    scroll_point = 0;

    /**
     * Register new modal
     */
    addModal(modalName: string, defaultParams: ModalParams = {}): void {
        const modal = this.getModalByName(modalName);
        if (modal) return;
        this.modals.push({
            name: modalName,
            default_params: defaultParams,
        });
        this.subscribers[modalName] = [];
        setTimeout(() => {
            this.callbacks.afterRegister.forEach((cb) =>
                cb(new CallbackParams(modalName))
            );
        }, 0);
    }

    /**
     * Remove modal with specific name
     */
    delModal(modalName: string): void {
        this.modals = this.modals.filter((m) => m.name !== modalName);
        this._openedModals.next(
            this._openedModals.getValue().filter((m) => m.name !== modalName)
        );
        delete this.subscribers[modalName];
    }

    /**
     * Get registred modal
     * @param modalName {string} name of modal
     */
    getModalByName(modalName: string): Modal | undefined {
        return this.modals.find((m) => m.name === modalName);
    }

    /**
     * Open modal with specific name and close another modals if <close_other> is true
     */
    openModal(
        modalName: string,
        closeOther = true,
        params?: ModalParams
    ): void {
        const modal = this.getModalByName(modalName);
        if (!modal) {
            throw new Error(`manager do not have modal '${modalName}'`);
        }
        this.callbacks.beforeOpen.forEach((cb) =>
            cb(new CallbackParams(modalName))
        );
        if (closeOther) {
            for (const opened_modal of this._openedModals.getValue()) {
                this.closeModal(opened_modal.name);
            }
        }
        this._openedModals.next([
            ...this._openedModals.getValue(),
            {
                name: modal.name,
                params: params || modal.default_params,
            },
        ]);
        this.callSubscribers(modalName);
        this.callbacks.afterOpen.forEach((cb) =>
            cb(new CallbackParams(modalName))
        );
    }

    /*
     * Close the modal with specific name
     */
    closeModal(modalName: string): void {
        const modal = this.getModalByName(modalName);
        if (!modal) {
            throw new Error(`manager not have modal with name '${modalName}'`);
        }
        this.callbacks.beforeClose.forEach((cb) =>
            cb(new CallbackParams(modalName))
        );
        const newOpenedModalsList = this._openedModals
            .getValue()
            .filter((m) => m.name !== modalName);
        this._openedModals.next(newOpenedModalsList);
        this.callSubscribers(modalName);
        this.callbacks.afterClose.forEach((cb) =>
            cb(new CallbackParams(modalName))
        );
    }

    /*
     * Define modal with name <modal_name> is opened
     */
    isOpen(modalName: string): boolean {
        const open_modal = this._openedModals
            .getValue()
            .find((m) => m.name === modalName);
        return !!open_modal;
    }

    getObservable(modalName: string): Observable<boolean> {
        const modal = this.getModalByName(modalName);
        if (!modal) {
            throw new Error(`manager not have modal with name '${modalName}'`);
        }
        return this._openedModals.pipe(
            skip(1),
            map((om) => {
                return om.find((om) => om.name === modalName);
            }),
            map((om) => !!om),
            distinctUntilChanged()
        );
    }

    getParamsObservable(
        modalName: string
    ): Observable<ModalParams | undefined> {
        const modal = this.getModalByName(modalName);
        if (!modal) {
            throw new Error(`manager not have modal with name '${modalName}'`);
        }
        return this._openedModals.pipe(
            skip(1),
            map((om) => {
                return om.find((om) => om.name === modalName);
            }),
            map((om) => om?.params),
            distinctUntilChanged()
        );
    }

    /**
     * Get parameters of opened modal, if modal is closed result will be 'null'
     * @param modalName name of modal
     * @returns {Object|Null}
     */
    getParams(modalName: string): ModalParams | null {
        const open_modal = this._openedModals
            .getValue()
            .find((m) => m.name === modalName);
        return open_modal?.params || null;
    }

    /**
     * Register function for modal, that will call on change of this modal
     */
    addSubscriber(modalName: string, subscriber: () => unknown): void {
        this.subscribers[modalName].push(subscriber);
    }

    /**
     * Remove subscriber for specific modal
     * Note: if all subscribers for modal_name are deleted, modal will be deleted
     */
    removeSubscriber(modalName: string, subscriber: () => unknown): void {
        this.subscribers[modalName] = this.subscribers[modalName].filter(
            (s) => s !== subscriber
        );
        // Delete modal if it do not have subscribers
        if (this.subscribers[modalName].length === 0) {
            this.delModal(modalName);
        }
    }

    /**
     * Call all registred subscribers for specific modal
     */
    private callSubscribers(modalName: string) {
        if (!Object.keys(this.subscribers).includes(modalName)) {
            throw new Error(`Subscribers for '${modalName} not defined'`);
        }
        for (const subscriber of this.subscribers[modalName]) {
            subscriber();
        }
    }

    /**
     * Register new callback for event
     */
    on(event: Event, cb: CallbackFunction): void {
        if (!ALLOWED_EVENTS.includes(event)) {
            throw new Error(`Unknown event ${event}`);
        }
        this.callbacks[event].push(cb);
    }
}
