import { BehaviorSubject } from "rxjs";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import CurrencyProviderInterface from "./CurrencyProviderInterface";

class FromShopifyCurrencyCurrencyProvider implements CurrencyProviderInterface {
    private currentCurrency$: BehaviorSubject<string>;
    constructor(private readonly logger: LoggerInterface) {
        const currency = Shopify.currency.active;
        this.currentCurrency$ = new BehaviorSubject<string>(currency);
        this.logger.debug(
            "FromShopifyCurrencyCurrencyProvider.selectCurrency success getting of current curreyncy",
            { currency }
        );
    }
    selectCurrency() {
        return this.currentCurrency$;
    }
}

export default FromShopifyCurrencyCurrencyProvider;
