import { globalLoggerToken } from "@/tokens";
import { BehaviorSubject, catchError, finalize, of, take, tap } from "rxjs";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import BrandingsApiServiceInterface from "../api_services/BrandingsApiServiceInterface";
import ReviewsBrandingEntity from "../entities/ReviewsBrandingEntity";
import RewardsBrandingEntity from "../entities/RewardsBrandingEntity";
import WishlistBrandingEntity from "../entities/WishlistBrandingEntity";
import { brandingsApiServiceToken } from "../tokens";
import BrandingProviderInterface from "./BrandingProviderInterface";

@singleton()
class BrandingFromApiProvider implements BrandingProviderInterface {
    private wishlistBrandingOptions =
        new BehaviorSubject<WishlistBrandingEntity | null>(null);

    private wishlistBrandingOptionsLoading = false;

    private reviewsBrandingOptions =
        new BehaviorSubject<ReviewsBrandingEntity | null>(null);

    private reviewsBrandingOptionsLoading = false;

    private rewardsBrandingOptions =
        new BehaviorSubject<RewardsBrandingEntity | null>(null);

    private rewardsBrandingOptionsLoading = false;

    constructor(
        @inject(brandingsApiServiceToken)
        private readonly brandingsApiService: BrandingsApiServiceInterface,
        @inject(globalLoggerToken)
        private readonly logger: LoggerInterface
    ) {}

    public selectWishlistBrandingOptions() {
        this.logger.debug("BrandingProvider.getWishlistBrandingOptions");

        if (
            !this.wishlistBrandingOptions.value &&
            !this.wishlistBrandingOptionsLoading
        ) {
            this.wishlistBrandingOptionsLoading = true;

            this.brandingsApiService
                .getWishlistSettings()
                .pipe(
                    tap((options) => {
                        this.wishlistBrandingOptions.next(options);
                    }),
                    catchError(() => of(null)),
                    finalize(() => {
                        this.wishlistBrandingOptionsLoading = false;
                    }),
                    take(1)
                )
                .subscribe();
        }

        return this.wishlistBrandingOptions.asObservable();
    }

    public selectReviewsBrandingOptions() {
        this.logger.debug("BrandingProvider.getReviewsBrandingOptions");

        if (
            !this.reviewsBrandingOptions.value &&
            !this.reviewsBrandingOptionsLoading
        ) {
            this.reviewsBrandingOptionsLoading = true;

            this.brandingsApiService
                .getReviewsSettings()
                .pipe(
                    tap((options) => {
                        this.reviewsBrandingOptions.next(options);
                    }),
                    catchError(() => of(null)),
                    finalize(() => {
                        this.reviewsBrandingOptionsLoading = false;
                    }),
                    take(1)
                )
                .subscribe();
        }

        return this.reviewsBrandingOptions.asObservable();
    }

    public selectRewardsBrandingOptions() {
        this.logger.debug("BrandingProvider.getRewardsBrandingOptions");

        if (
            !this.rewardsBrandingOptions.value &&
            !this.rewardsBrandingOptionsLoading
        ) {
            this.rewardsBrandingOptionsLoading = true;

            this.brandingsApiService
                .getRewardsSettings()
                .pipe(
                    tap((options) => {
                        this.rewardsBrandingOptions.next(options);
                    }),
                    catchError(() => of(null)),
                    finalize(() => {
                        this.rewardsBrandingOptionsLoading = false;
                    }),
                    take(1)
                )
                .subscribe();
        }

        return this.rewardsBrandingOptions.asObservable();
    }
}

export default BrandingFromApiProvider;
