import { JsonMimeType } from "@lib/ApiClient";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import CartEntity from "@modules/cart/entities/CartEntity";
import CartSchema from "@modules/cart/schemas/CartSchema";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { create } from "superstruct";
import { singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

@singleton()
class ShopifyCartApiService {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly logger: LoggerInterface
    ) {}
    getCart(): Observable<CartEntity> {
        this.logger.debug("CartService.getCart is started");
        return this.apiClient
            .get({
                url: "/cart.js",
                contentType: JsonMimeType,
            })
            .pipe(
                map((v) => create(v.body, CartSchema)),
                catchError((error: unknown) => {
                    this.logger.debug("CartService.getCart is errored");
                    throw error;
                }),
                tap(() => {
                    this.logger.debug("CartService.getCart is ready");
                })
            );
    }
    addToCart(
        items: { variantID: number; quantity: number }[]
    ): Observable<unknown> {
        this.logger.debug("CartService.addToCart is started", {
            items,
        });
        return this.apiClient
            .post({
                url: "/cart/add.js",
                body: JSON.stringify({
                    items: items.map(({ variantID, quantity }) => ({
                        id: variantID,
                        quantity: quantity,
                    })),
                }),
                contentType: JsonMimeType,
            })
            .pipe(
                catchError((error: unknown) => {
                    this.logger.debug("CartService.addToCart is errored", {
                        items,
                    });
                    throw error;
                }),
                tap(() => {
                    this.logger.debug("CartService.addToCart is ready", {
                        items,
                    });
                })
            );
    }
    updateCart(
        updates: { variantID: number; quantity: number }[]
    ): Observable<unknown> {
        this.logger.debug("CartService.updateCart is started", {
            updates,
        });
        return this.apiClient
            .post({
                url: "/cart/add.js",
                body: JSON.stringify({
                    updates: updates.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.variantID]: item.quantity,
                        }),
                        {}
                    ),
                }),
                contentType: JsonMimeType,
            })
            .pipe(
                catchError((error: unknown) => {
                    this.logger.debug("CartService.updateCart is errored", {
                        updates,
                    });
                    throw error;
                }),
                tap(() => {
                    this.logger.debug("CartService.updateCart is ready", {
                        updates,
                    });
                })
            );
    }
}

export default ShopifyCartApiService;
