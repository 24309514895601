import FetchAndXHRPatcher, {
    CatchedRequest,
} from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import { Observable, combineLatest, from, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import CartListenerStrategyInterface from "./CartListenerStrategyInterface";
import { AddToCartEvent } from "./types";

class FetchOrXHRPatcherListenerStrategy
    implements CartListenerStrategyInterface
{
    constructor(
        private readonly logger: LoggerInterface,
        private readonly fetchAndXhrPatcher: FetchAndXHRPatcher
    ) {}
    listen(): Observable<AddToCartEvent> {
        return this.fetchAndXhrPatcher.requests$.pipe(
            filter((request: CatchedRequest) => {
                return request.url.includes("/cart/add");
            }),
            filter(
                (
                    request: CatchedRequest
                ): request is Omit<CatchedRequest, "body"> & {
                    body: FormData | string;
                } => {
                    return (
                        request.body instanceof FormData ||
                        typeof request.body === "string"
                    );
                }
            ),
            // combine request and response for waiting response before processins next steps
            switchMap((request) => {
                return combineLatest([of(request), from(request.response)]);
            }),
            map(([request]) => {
                if (typeof request.body === "string") {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
                    return JSON.parse(request.body)["id"];
                } else if (request.body instanceof FormData) {
                    return request.body.get("id");
                }
            }),
            map((variantId) => {
                if (!variantId) {
                    throw new Error(
                        "id not found in body of request to /cart/add"
                    );
                }
                this.logger.debug(
                    "FetchOrXHRPatcherListenerStrategy receive request info",
                    {
                        variantId: variantId as string,
                    }
                );
                return {
                    variantId: parseInt(variantId as string),
                };
            })
        );
    }
}

export default FetchOrXHRPatcherListenerStrategy;
