import GrowaveTokenManager from "@lib/TokenManager/GrowaveTokenManager";

export class LegacyAdapter {
    constructor(
        private readonly jwtToken: Promise<string>,
        private readonly tokenManager: GrowaveTokenManager
    ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (!(window as any).gw) (window as any).gw = {};
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (window as any).gw.legacyAdapter = this;
        window.dispatchEvent(new Event("gw:legacyAdapter:ready"));
    }

    public getHashKey() {
        return this.tokenManager.getTokenPayload()?.data.sessionToken;
    }

    public getHESID() {
        return this.tokenManager.getTokenPayload()?.data.sessionToken;
    }

    public runAfterCheck(cb: (...args: any[]) => any) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        void this.jwtToken.then(() => cb());
    }
}
