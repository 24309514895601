import { JsonMimeType } from "@lib/ApiClient";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import RefreshTokenServiceInterface from "@lib/TokenManager/RefreshTokenServiceInterface";
import TokensResponseSchema from "@lib/TokenManager/schemas/TokensResponseSchema";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Infer, create } from "superstruct";
import { singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

@singleton()
class RefreshTokenService implements RefreshTokenServiceInterface {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly logger: LoggerInterface
    ) {}
    refreshToken(
        currentSessionToken: string
    ): Observable<Infer<typeof TokensResponseSchema>> {
        this.logger.debug("RefreshTokenService.refreshToken");
        return this.apiClient
            .post({
                url: "/refresh",
                responseType: "json",
                contentType: JsonMimeType,
                body: JSON.stringify({
                    currentSessionToken,
                }),
            })
            .pipe(
                map((response) => {
                    return create(response.body, TokensResponseSchema);
                })
            );
    }
}

export default RefreshTokenService;
