import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import TokenManagerInterface from "@lib/TokenManager/TokenManagerInterface";
import { Observable, from, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { create, object, string } from "superstruct";
import { singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

export const CheckResponseSchema = object({
    token: string(),
});

@singleton()
class CheckService {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly tokenManager: TokenManagerInterface,
        private readonly logger: LoggerInterface
    ) {}
    /**
     * Call check endpoint and returns JWT token
     * @returns {Observable<string>} - token
     */
    check(): Observable<string> {
        this.logger.debug("CheckService.check starts");
        return (
            this.tokenManager.hasToken() && this.tokenManager.tokenIsExpired()
                ? from(this.tokenManager.refreshToken())
                : of(true)
        ).pipe(
            switchMap((tokenManagerIsReady) => {
                if (!tokenManagerIsReady) {
                    this.tokenManager.discardToken();
                    throw new Error("Something went wrong");
                }
                const currentToken = this.tokenManager.getToken();
                return this.apiClient
                    .get({
                        url: "/check",
                        queryParams: {
                            ...(currentToken ? { token: currentToken } : {}),
                        },
                    })
                    .pipe(
                        catchError((error: unknown) => {
                            this.logger.error("CheckService.check error", {
                                error,
                            });
                            this.tokenManager.discardToken();
                            throw error;
                        }),
                        tap((response) => {
                            this.logger.debug("CheckService.check finised", {
                                response,
                            });
                        }),
                        map((response) => {
                            return create(response.body, CheckResponseSchema);
                        }),
                        map((body) => {
                            this.tokenManager.setToken(body.token);
                            // this.tokenManager.setRefreshToken(body.refreshToken);
                            this.tokenManager.setIsInitialized();
                            return body.token;
                        })
                    );
            })
        );
    }
}

export default CheckService;
