import { Observable, of } from "rxjs";

import MoneyExchangerInterface from "./MoneyExchangerInterface";

class FakeMoneyExchanger implements MoneyExchangerInterface {
    exchange(amount: number, targetCurrency: string): Observable<number> {
        return of(amount);
    }
}

export default FakeMoneyExchanger;
