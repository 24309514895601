import type AnalyticsEventsCollectorInterface from "@lib/AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";
import type ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import type CurrencyProviderInterface from "@lib/CurrencyProvider/CurrencyProviderInterface";
import { CustomerInfoProviderInterface } from "@lib/CustomerInfoProvider/CustomerInfoProviderInterface";
import FetchAndXHRPatcher from "@lib/FetchAndXHRPatcher/FetchAndXHRPatcher";
import type MoneyFormatterInterface from "@lib/Formatters/MoneyFormatter/MoneyFormatterInterface";
import GrowaveConfigurationEntity from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationEntity";
import { GrowaveConfigurationProviderInterface } from "@lib/GrowaveConfigurationProvider/GrowaveConfigurationProviderInterface";
import { GwI18n } from "@lib/GwI18n/GwI18n";
import I18nCriticalLoaderFactory from "@lib/GwI18n/I18nCriticalLoaderFactory";
import type HTMLSanitizerInterface from "@lib/HTMLSanitizer/HTMLSanitizerInterface";
import { LocalizationInfoProviderInterface } from "@lib/LocalizationInfoProvider/LocalizationInfoProviderInterface";
import type LocationControllerInterface from "@lib/LocationController/LocationControllerInterfce";
import type MoneyExchangerInterface from "@lib/MoneyExchanger/MoneyExchangerInterface";
import { ProductIdManagerInterface } from "@lib/ProductIdManager/ProductIdManagerInterface";
import { ProductVariantIdManagerInterface } from "@lib/ProductVariantIdManager/ProductVariantIdManagerInterface";
import type { ReferrerProviderInterface } from "@lib/ReferrerProvider";
import type SettingsManagerInterface from "@lib/SettingsManager/SettingsManagerInterface";
import { StorefrontApiAccessTokenProviderInterface } from "@lib/StorefrontApiAccessTokenProvider/StorefrontApiAccessTokenProviderInterface";
import GrowaveTokenManager from "@lib/TokenManager/GrowaveTokenManager";
import type CartListenerInterface from "@modules/cart/utils/CartListener/CartListenerInterface";
import { TrackingApiService } from "@modules/tracking_events/services/TrackingApiService";
import type { InjectionToken } from "tsyringe";

import { ModalManager } from "@components/Modal/ModalManager";
import type GwStorefrontAppInfo from "@interfaces/GwStorefrontAppInfo";
import type { LoggerInterface } from "@interfaces/LoggerInterface";

import TranslationsApiService from "./services/TranslationsApiService/TranslationsApiService";
import { ShopifyUrlUtils } from "./utils/shopify/ShopifyUrlUtils";

export const eventsCollectorToken = Symbol(
    "eventsCollectorToken"
) as InjectionToken<AnalyticsEventsCollectorInterface>;

// API Clients
export const apiClientToken = Symbol(
    "apiClientToken"
) as InjectionToken<ApiClientInterface>;

export const socialLoginApiClientToken = Symbol(
    "socialLoginApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const fileUploaderAuthApiClientToken = Symbol(
    "fileUploaderAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const eventsCollectorAuthApiClientToken = Symbol(
    "eventsCollectorAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const phpAuthApiClientToken = Symbol(
    "phpAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const commonAuthApiClientToken = Symbol(
    "commonAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

export const translationsAuthApiClientToken = Symbol(
    "translationsAuthApiClientToken"
) as InjectionToken<ApiClientInterface>;

//Utils
export const localStorageToken = Symbol("localStorageToken");

export const tokenManagerToken = Symbol(
    "tokenManagerToken"
) as InjectionToken<GrowaveTokenManager>;

export const globalLoggerToken = Symbol(
    "globalLoggerToken"
) as InjectionToken<LoggerInterface>;

export const gwStorefrontAppInfoToken = Symbol(
    "authApiClientToken"
) as InjectionToken<GwStorefrontAppInfo>;

export const customerInfoProviderToken = Symbol(
    "customerInfoProviderToken"
) as InjectionToken<CustomerInfoProviderInterface>;

export const storefrontApiAccessTokenProviderToken = Symbol(
    "storefrontApiAccessTokenProviderToken"
) as InjectionToken<StorefrontApiAccessTokenProviderInterface>;

export const growaveConfigurationProviderToken = Symbol(
    "growaveConfigurationProviderToken"
) as InjectionToken<GrowaveConfigurationProviderInterface>;

export const growaveConfigurationToken = Symbol(
    "growaveConfigurationToken"
) as InjectionToken<GrowaveConfigurationEntity>;

export const locationControllerToken = Symbol(
    "locationControllerToken"
) as InjectionToken<LocationControllerInterface>;

export const shopifyUrlUtilsToken = Symbol(
    "shopifyUrlUtils"
) as InjectionToken<ShopifyUrlUtils>;

export const settingsManagerToken = Symbol(
    "settingsManagerToken"
) as InjectionToken<SettingsManagerInterface>;

export const moneyFormatterToken = Symbol(
    "moneyFormatterToken"
) as InjectionToken<MoneyFormatterInterface>;

export const moneyExchangerToken = Symbol(
    "moneyExchangerToken"
) as InjectionToken<MoneyExchangerInterface>;

export const currencyProviderToken = Symbol(
    "currencyProvider"
) as InjectionToken<CurrencyProviderInterface>;

export const cartListenerToken = Symbol(
    "cartListener"
) as InjectionToken<CartListenerInterface>;

export const referrerProviderToken = Symbol(
    "referrerProvider"
) as InjectionToken<ReferrerProviderInterface>;

export const fetchAndXhrPatcherToken = Symbol(
    "fetchAndXhrPatcher"
) as InjectionToken<FetchAndXHRPatcher>;

export const htmlSanitizerToken = Symbol(
    "htmlSanitizer"
) as InjectionToken<HTMLSanitizerInterface>;

export const gwI18nToken = Symbol("i18nToken") as InjectionToken<GwI18n>;

export const trackingApiServiceToken = Symbol(
    "trackingApiServiceToken"
) as InjectionToken<TrackingApiService>;

export const translationsApiServiceToken = Symbol(
    "translationsApiServiceToken"
) as InjectionToken<TranslationsApiService>;

export const sharedTokens = {
    globalLoggerToken,
    eventsCollectorToken,
};

export const modalManagerToken = Symbol(
    "modalManagerToken"
) as InjectionToken<ModalManager>;

export const localizationInfoProviderToken = Symbol(
    "localizationInfoProviderToken"
) as InjectionToken<LocalizationInfoProviderInterface>;

export const i18nCriticalLoaderFactoryToken = Symbol(
    "i18nCriticalLoaderFactoryToken"
) as InjectionToken<I18nCriticalLoaderFactory>;

export const isEmbeddedModeToken = Symbol(
    "isEmbeddedModeToken"
) as InjectionToken<boolean>;

export const globalProductIdManagerToken = Symbol(
    "globalProductIdManagerToken"
) as InjectionToken<ProductIdManagerInterface>;

export const globalProductVariantsIdManagerToken = Symbol(
    "globalProductVariantsIdManagerToken"
) as InjectionToken<ProductVariantIdManagerInterface>;
