export enum CUSTOM_FORM_TYPE {
    RADIOBUTTON = "radiobutton",
    CHECKBOX = "checkbox",
    TEXTFIELD = "textfield",
    SELECTBOX = "selectbox",
    RATING = "rating",
    FIT = "fit",
}

export const PRODUCT_REVIEW_FORM_ID = "gw-rv-product-review-form";
export const REVIEW_DRAWER_FORM_ID = "review_drawer_form_id";
export const SITE_REVIEW_FORM_ID = "gw-rv-site-review-form";
export const QUESTION_FORM_ID = "gw-qa-form";

export enum REVIEW_TYPE {
    SITE_REVIEW = "site_review",
    PRODUCT_REVIEW = "product_review",
}

export enum REVIEWS_WIDGET_LAYOUT {
    REVIEWS_LISTING_LAYOUT = "reviewsListingLayout",
    REVIEWS_PHOTO_LAYOUT = "reviewsPhotoLayout",
}

export const REVIEW_FORM_MODAL = "openReviewForm";

export const SCROLL_REVIEWS_TAB = "scrollReviewsTab";
export const SCROLL_QUESTIONS_TAB = "scrollQuestionsTab";
