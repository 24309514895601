export const SDK_READY = "gwSdkReady";

export enum METHOD_STATUSES {
    SUCCESS = "success",
}

export enum ERROR_CODES {
    GENERAL_ERROR = "generalError",
    NO_SUBSCRIBERS = "noSubscribers",
    INSUFFICIENT_ARGS = "insufficientArgs",
    TIMEOUT_ERROR = "timeoutError",
}

export const PROMISE_TIMEOUT_MS = 15000;
