import { enums, nullable, number, object, string } from "superstruct";

const CustomFormOptionSchema = object({
    id: number(),
    displayName: string(),
    rate: nullable(enums([0, 1, 2, 3, 4, 5])),
    questionId: number(),
});

export default CustomFormOptionSchema;
