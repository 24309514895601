import { Infer, object, string, type } from "superstruct";

import ReviewsBrandingSchema from "./ReviewsBrandingSchema";
import RewardsBrandingSchema from "./RewardsBrandingSchema";
import WishlistBrandingSchema from "./WishlistBrandingSchema";

export const BrandingOptionsSchema = object({
    login: object({
        brand: string(),
        text: string(),
        background: string(),
    }),
    reviews: ReviewsBrandingSchema,
    rewards: RewardsBrandingSchema,
    wishlist: WishlistBrandingSchema,
});

export const AdditionalInfoBrandingSchema = type({
    GW_BRANDING_OPTIONS: BrandingOptionsSchema,
});

export type AdditionalInfoBranding = Infer<typeof AdditionalInfoBrandingSchema>;
