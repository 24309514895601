import { loginApplicationLoggerToken } from "@apps/login/tokens";
import { inject, singleton } from "tsyringe";

import type { LoggerInterface } from "@interfaces/LoggerInterface";

import type { CustomizationData, WidgetConfig } from "./types";

@singleton()
class CustomizationManager {
    constructor(
        @inject(loginApplicationLoggerToken)
        private readonly logger: LoggerInterface
    ) {}

    private getCustomization(config: WidgetConfig) {
        if (!window.gw.customizations) return;

        let customization: Partial<CustomizationData> = {};

        window.gw.customizations.forEach(([selectWidget, customizeWidget]) => {
            if (selectWidget(config)) {
                customization = customizeWidget(config);

                this.logger.debug("CustomizationManager.getCustomization", {
                    customization,
                });
            }
        });

        return customization;
    }

    public injectStyles(config: WidgetConfig): void {
        const customization = this.getCustomization(config);

        if (!(config.shadowRoot && customization?.styleElement)) return;

        config.shadowRoot.appendChild(
            customization.styleElement.cloneNode(true)
        );

        this.logger.debug(
            "CustomizationManager.injectStyles",
            customization.styleElement
        );
    }

    public async beforeWidgetInject(config: WidgetConfig): Promise<void> {
        const customization = this.getCustomization(config);
        const callback = customization?.beforeWidgetInject;

        if (!callback) return;

        if (callback() instanceof Promise) {
            await callback();
        } else {
            void callback();
        }

        this.logger.debug("CustomizationManager.beforeWidgetInject");
    }

    public afterWidgetInject(config: WidgetConfig): void {
        const customization = this.getCustomization(config);
        const callback = customization?.afterWidgetInject;

        if (!callback) return;

        callback();

        this.logger.debug("CustomizationManager.afterWidgetInject");
    }
}

export default CustomizationManager;
