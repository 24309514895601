import { InjectionToken } from "tsyringe";

import SdkAnalyticsEventsFactory from "./analytics/SdkAnalyticsEventsFactory";
import SdkMethodsCollector from "./methods/SdkMethodsCollector";
import Sdk from "./Sdk";

export const sdkToken = Symbol("sdkToken") as InjectionToken<Sdk>;

export const sdkMethodsCollectorToken = Symbol(
    "sdkMethodsCollectorToken"
) as InjectionToken<SdkMethodsCollector>;

export const sdkAnalyticsEventsFactoryToken = Symbol(
    "sdkAnalyticsEventsFactoryToken"
) as InjectionToken<SdkAnalyticsEventsFactory>;
