import OffsetPaginationMetaSchema from "@/schemas/OffsetPaginationSchema";
import ReviewSchema from "@apps/reviews/schemas/ReviewSchema";
import { array, assign, object } from "superstruct";

const ReviewsListResponseSchema = assign(
    OffsetPaginationMetaSchema,
    object({
        items: array(ReviewSchema),
    })
);

export default ReviewsListResponseSchema;
