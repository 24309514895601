import { Query } from "@datorama/akita";
import { singleton } from "tsyringe";

import ReviewsSettingsStore, {
    ReviewsSettingsState,
} from "../stores/ReviewsSettingsStore";

@singleton()
class ReviewsSettingsQuery extends Query<ReviewsSettingsState> {
    constructor(protected store: ReviewsSettingsStore) {
        super(store);
    }

    settings$ = this.select((v) => v.settings);
}

export default ReviewsSettingsQuery;
