import I18nLoaderInterface from "@lib/GwI18n/I18nLoaderInterface";
import { lastValueFrom } from "rxjs";

import { TranslationsApiServiceInterface } from "./TranslationsApiServiceInterface";

export class I18nApiLoader implements I18nLoaderInterface {
    constructor(
        private readonly namespace: string,
        private readonly locale: string,
        private readonly translationsApiService: TranslationsApiServiceInterface
    ) {}

    async load() {
        return lastValueFrom(
            this.translationsApiService.getTranslations(
                this.namespace,
                this.locale
            )
        );
    }
}
