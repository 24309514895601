import type AnalyticsEventsCollectorInterface from "@lib/AnalyticsEventsCollector/AnalyticsEventsCollectorInterface";
import { TranslationOptions, TranslationsDataType } from "@lib/GwI18n/types";
import Polyglot from "node-polyglot";
import { Observable, from } from "rxjs";
import { map, share, tap } from "rxjs/operators";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import I18nLoaderInterface from "./I18nLoaderInterface";

export class GwI18n {
    private polyglot: Polyglot;
    private loadersLoadingObservables: Map<
        string,
        Observable<TranslationsDataType>
    > = new Map();
    private loaders: Map<string, I18nLoaderInterface> = new Map();

    constructor(
        private readonly logger: LoggerInterface,
        private readonly eventsCollector: AnalyticsEventsCollectorInterface
    ) {
        this.polyglot = new Polyglot({
            interpolation: {
                prefix: "{{",
                suffix: "}}",
            },
        });
    }

    registerLoader(namespace: string, loader: I18nLoaderInterface) {
        this.loaders.set(namespace, loader);
    }

    selectT(
        namespace: string,
        translationKey: string,
        translationOptions?: TranslationOptions
    ): Observable<string> {
        const loader = this.loaders.get(namespace);
        if (!loader) {
            throw new Error(`Loader for namespace '${namespace}' not found'`);
        }
        let loaderObservable = this.loadersLoadingObservables.get(namespace);
        if (!loaderObservable) {
            loaderObservable = from(loader.load()).pipe(
                tap((result) => {
                    this.polyglot.extend({
                        [namespace]: result,
                    });
                }),
                share()
            );
            this.loadersLoadingObservables.set(namespace, loaderObservable);
        }
        return loaderObservable.pipe(
            map(() => {
                return this.polyglot.t(
                    `${namespace}.${translationKey}`,
                    translationOptions
                );
            })
        );
    }
}
