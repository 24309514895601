import { array, object, string, type } from "superstruct";

export const GetTranslationsResponseSchema = object({
    locale: string(),
    items: array(
        type({
            key: string(),
            namespace: string(),
            value: string(),
        })
    ),
});
