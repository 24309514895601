import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { catchError, map } from "rxjs/operators";
import { create } from "superstruct";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import ReviewsBrandingSchema from "../schemas/ReviewsBrandingSchema";
import RewardsBrandingSchema from "../schemas/RewardsBrandingSchema";
import WishlistBrandingSchema from "../schemas/WishlistBrandingSchema";
import BrandingsApiServiceInterface from "./BrandingsApiServiceInterface";

class BrandingsApiService implements BrandingsApiServiceInterface {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly logger: LoggerInterface
    ) {}

    public getRewardsSettings() {
        return this.apiClient
            .get({
                url: "rewards/getSettings",
                responseType: "json",
            })
            .pipe(
                map((response) => {
                    const result = create(response.body, RewardsBrandingSchema);
                    return result;
                }),
                catchError((error: unknown) => {
                    this.logger.debug(
                        "BrandingsApiService.getRewardsSettings is errored",
                        error
                    );
                    throw error;
                })
            );
    }

    public getWishlistSettings() {
        return this.apiClient
            .get({
                url: "wishlist/getSettings",
                responseType: "json",
            })
            .pipe(
                map((response) => {
                    const result = create(
                        response.body,
                        WishlistBrandingSchema
                    );
                    return result;
                }),
                catchError((error: unknown) => {
                    this.logger.debug(
                        "BrandingsApiService.getWishlistSettings is errored",
                        error
                    );
                    throw error;
                })
            );
    }

    public getReviewsSettings() {
        return this.apiClient
            .get({
                url: "reviews/getSettings",
                responseType: "json",
            })
            .pipe(
                map((response) => {
                    const result = create(response.body, ReviewsBrandingSchema);
                    return result;
                }),
                catchError((error: unknown) => {
                    this.logger.debug(
                        "BrandingsApiService.getReviewsSettings is errored",
                        error
                    );
                    throw error;
                })
            );
    }
}

export default BrandingsApiService;
