import { eventsCollectorToken } from "@/tokens";
import { ProductIdFromSdkProvider } from "@lib/ProductIdManager/ProductIdFromSdkProvider";
import { ProductVariantIdFromSdkProvider } from "@lib/ProductVariantIdManager/ProductVariantIdFromSdkProvider";
import { DependencyContainer, instanceCachingFactory } from "tsyringe";

import SdkAnalyticsEventsFactory from "./analytics/SdkAnalyticsEventsFactory";
import CommonSdk from "./common_sdk/CommonSdk";
import { COMMON_SDK_NAME } from "./common_sdk/constants";
import SdkMethodsCollector from "./methods/SdkMethodsCollector";
import Sdk from "./Sdk";
import {
    sdkAnalyticsEventsFactoryToken,
    sdkMethodsCollectorToken,
    sdkToken,
} from "./tokens";
import { SdkInitOptions } from "./types";

export class SdkModule {
    registerProviders(
        di: DependencyContainer,
        initApp: (options: SdkInitOptions) => void
    ) {
        di.register(sdkAnalyticsEventsFactoryToken, {
            useFactory: instanceCachingFactory(() => {
                return new SdkAnalyticsEventsFactory(
                    navigator.userAgent,
                    location.href,
                    __APP_NAME__
                );
            }),
        });

        di.register(sdkToken, {
            useFactory: instanceCachingFactory(() => {
                return new Sdk(
                    initApp,
                    di.resolve(eventsCollectorToken),
                    di.resolve(sdkAnalyticsEventsFactoryToken)
                );
            }),
        });

        di.register(sdkMethodsCollectorToken, {
            useFactory: instanceCachingFactory(
                () =>
                    new SdkMethodsCollector(
                        di.resolve(eventsCollectorToken),
                        di.resolve(sdkAnalyticsEventsFactoryToken)
                    )
            ),
        });

        di.resolve(sdkToken).registerModule(
            COMMON_SDK_NAME,
            new CommonSdk(
                di.resolve(ProductIdFromSdkProvider),
                di.resolve(ProductVariantIdFromSdkProvider)
            )
        );

        di.resolve(sdkToken).start();
    }
}
