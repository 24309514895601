import GwCurrency from "@lib/GwCurrency/GwCurrency";
import { Observable } from "rxjs";

import MoneyExchangerInterface from "./MoneyExchangerInterface";

class LegacyMoneyExchanger implements MoneyExchangerInterface {
    constructor(
        private readonly baseCurrency: string,
        private readonly gwCurrency: GwCurrency
    ) {}
    exchange(amount: number, targetCurrency: string): Observable<number> {
        return this.gwCurrency.convert(
            amount,
            this.baseCurrency,
            targetCurrency
        );
    }
}

export default LegacyMoneyExchanger;
