import { JsonMimeType } from "@lib/ApiClient";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { I18nApiRecords } from "@lib/GwI18n/loaders/I18nApiLoader/schemas";
import { TranslationsApiServiceInterface } from "@lib/GwI18n/loaders/I18nApiLoader/TranslationsApiServiceInterface";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { create } from "superstruct";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { GetTranslationsResponseSchema } from "./TranslationsResponseSchema";

class TranslationsApiService implements TranslationsApiServiceInterface {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly logger: LoggerInterface
    ) {}

    public getTranslations(
        namespace: string,
        locale: string
    ): Observable<I18nApiRecords> {
        return this.apiClient
            .get({
                url: "/storefront-api/translations",
                responseType: "json",
                contentType: JsonMimeType,
                queryParams: {
                    namespace,
                    locale,
                },
            })
            .pipe(
                map((response) => {
                    const result = create(
                        response.body,
                        GetTranslationsResponseSchema
                    );
                    return result.items.reduce((acc, current) => {
                        return {
                            ...acc,
                            [current.key]: current.value,
                        };
                    }, {});
                })
            );
    }
}

export default TranslationsApiService;
