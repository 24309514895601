import { STATUS } from "@/constants/statuses";
import ProductEntity from "@apps/iloom_customization/modules/products/entities/ProductEntity";
import {
    EntityState,
    EntityStore,
    StoreConfig,
    arrayAdd,
    arrayUpdate,
} from "@datorama/akita";
import { injectable } from "tsyringe";

const IN_QUEUE_STATUS = "IN_QUEUE";

type StatusItemKey = {
    productId: number;
    status: STATUS.LOADING | STATUS.READY | typeof IN_QUEUE_STATUS;
};

export interface ProductState extends EntityState<ProductEntity, number> {
    productStatuses: StatusItemKey[];
}

export function createInitialState(): ProductState {
    return {
        productStatuses: [],
    };
}

@injectable()
@StoreConfig({ name: "ProductStore" })
export class ProductStore extends EntityStore<ProductState> {
    constructor() {
        super(createInitialState());
    }

    public addProductInQueue(productId: number) {
        this.update(({ productStatuses }) => {
            if (
                !productStatuses.find(
                    (statusItem) => statusItem.productId === productId
                )
            ) {
                return {
                    productStatuses: arrayAdd(productStatuses, {
                        productId,
                        status: IN_QUEUE_STATUS,
                    }),
                };
            }
        });
    }

    public setLoadingStatus(productId: number) {
        this.update(({ productStatuses }) => ({
            productStatuses: arrayUpdate(
                productStatuses,
                ({ productId: _productId }) => _productId === productId,
                { status: STATUS.LOADING } as StatusItemKey
            ),
        }));
    }

    public setReadyStatus(productId: number) {
        this.update(({ productStatuses }) => ({
            productStatuses: arrayUpdate(
                productStatuses,
                ({ productId: _productId }) => _productId === productId,
                { status: STATUS.READY } as StatusItemKey
            ),
        }));
    }
}
