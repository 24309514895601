import { ApiClientResponse, JsonMimeType } from "@lib/ApiClient";
import ApiClientInterface from "@lib/ApiClient/ApiClientInterface";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

interface TrackAddToCartBody {
    appName: string;
    cartToken: string;
    variantId: number;
    cartReferrer: string;
}

@singleton()
export class TrackingApiService {
    constructor(
        private readonly apiClient: ApiClientInterface,
        private readonly logger: LoggerInterface
    ) {}

    trackAddToCart(
        reqBody: TrackAddToCartBody
    ): Observable<ApiClientResponse<unknown>> {
        this.logger.debug("TrackingApiService.trackAddToCart is started", {
            reqBody,
        });
        return this.apiClient
            .post({
                url: "/events/trackAddToCart",
                body: JSON.stringify({ ...reqBody }),
                responseType: "text",
                contentType: JsonMimeType,
            })
            .pipe(
                tap(() => {
                    this.logger.debug(
                        "TrackingApiService.trackAddToCart is ready",
                        {
                            reqBody,
                        }
                    );
                })
            );
    }
}
