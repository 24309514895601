import { InjectionToken } from "tsyringe";

import { ProductApiServiceInterface } from "./api_services/ProductApiServiceInterface";
import { ProductQuery } from "./queries/ProductQuery";
import { ProductService } from "./services/ProductService";
import { ProductStore } from "./stores/ProductStore";

export const productsApiServiceToken = Symbol(
    "productsApiService"
) as InjectionToken<ProductApiServiceInterface>;

export const productsStoreToken = Symbol(
    "productsStore"
) as InjectionToken<ProductStore>;

export const productsServiceToken = Symbol(
    "productsService"
) as InjectionToken<ProductService>;

export const productQueryToken = Symbol(
    "productsQuery"
) as InjectionToken<ProductQuery>;
