import {
    array,
    boolean,
    nullable,
    number,
    object,
    string,
    type,
} from "superstruct";

const ImageSchema = object({
    url: string(),
    id: string(),
    altText: nullable(string()),
});

export const ShopifyGraphQLProductResponseSchema = object({
    product: type({
        id: string(),
        title: string(),
        description: string(),
        handle: string(),
        featuredImage: nullable(ImageSchema),
        images: object({
            nodes: array(ImageSchema),
        }),
        options: array(
            object({
                id: string(),
                name: string(),
                values: array(string()),
            })
        ),
        variants: object({
            nodes: array(
                object({
                    id: string(),
                    title: string(),
                    image: nullable(ImageSchema),
                    price: object({
                        amount: string(),
                        currencyCode: string(),
                    }),
                    compareAtPrice: nullable(
                        object({
                            amount: string(),
                            currencyCode: string(),
                        })
                    ),
                    selectedOptions: array(
                        object({
                            name: string(),
                            value: string(),
                        })
                    ),
                    currentlyNotInStock: boolean(),
                    quantityAvailable: number(),
                    availableForSale: boolean(),
                })
            ),
        }),
        product_wish_button: nullable(
            object({
                value: string(),
            })
        ),
        tags: array(string()),
    }),
});
