import { boolean, nullable, number, object, string } from "superstruct";

const CustomerSchema = object({
    firstName: nullable(string()),
    id: number(),
    lastName: nullable(string()),
    verifiedBuyer: boolean(),
});

export default CustomerSchema;
