import {
    array,
    boolean,
    nullable,
    number,
    object,
    optional,
    record,
    string,
} from "superstruct";

import CartItemSchema from "./CartItemSchema";
import DiscountSchema from "./DiscountSchema";

const CartSchema = object({
    token: string(),
    note: nullable(string()),
    attributes: record(string(), string()),
    original_total_price: number(),
    total_price: number(),
    total_discount: number(),
    total_weight: number(),
    item_count: number(),
    items: array(CartItemSchema),
    requires_shipping: boolean(),
    currency: string(),
    items_subtotal_price: number(),
    cart_level_discount_applications: optional(array(DiscountSchema)),
});

export default CartSchema;
