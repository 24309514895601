import { CustomerInfoProviderInterface } from "@lib/CustomerInfoProvider/CustomerInfoProviderInterface";
import { ROLE } from "@lib/TokenManager/constants";
import GrowaveTokenManager from "@lib/TokenManager/GrowaveTokenManager";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import {
    customerInfoProviderToken,
    globalLoggerToken,
    tokenManagerToken,
} from "./tokens";

@singleton()
export class CurrentTokenChecker {
    public constructor(
        @inject(customerInfoProviderToken)
        private readonly customerInfoProvider: CustomerInfoProviderInterface,
        @inject(tokenManagerToken)
        private readonly tokenManager: GrowaveTokenManager,
        @inject(globalLoggerToken) private readonly logger: LoggerInterface
    ) {}
    public check() {
        // Reset JWT after logout
        if (
            !this.customerInfoProvider.getId() &&
            this.tokenManager.getTokenPayload()?.data.role === ROLE.Customer
        ) {
            this.logger.info("Reset token, after loguout");
            this.tokenManager.discardToken();
        }

        // Reset JWT if shopifyCustomerId in JWT not equal customerId from customerInfoProvider(liquid)
        if (
            this.tokenManager.getTokenPayload()?.data.shopifyCustomerId &&
            this.tokenManager.getTokenPayload()?.data.shopifyCustomerId !==
                this.customerInfoProvider.getId()
        ) {
            this.logger.info(
                "Reset token, because the token was created for another customer"
            );
            this.tokenManager.discardToken();
        }
    }
}
