import { InjectionToken } from "tsyringe";

import { CartQuery } from "./queries/CartQuery";
import { CartService } from "./services/CartService";
import { CartStore } from "./stores/CartStore";
import CartManagerCounterUpdatersContext from "./utils/CartManager/CartManagerCounterUpdatersContext";
import CartManagerItemsUpdatersContext from "./utils/CartManager/CartManagerItemsUpdatersContext";

export const cartServiceToken = Symbol(
    "cartServiceToken"
) as InjectionToken<CartService>;

export const cartStoreToken = Symbol(
    "cartStoreToken"
) as InjectionToken<CartStore>;

export const cartQueryToken = Symbol(
    "cartQueryToken"
) as InjectionToken<CartQuery>;

export const cartManagerItemsUpdatersContextToken = Symbol(
    "cartManagerItemsUpdatersContextToken"
) as InjectionToken<CartManagerItemsUpdatersContext>;

export const cartManagerCounterUpdatersContextToken = Symbol(
    "cartManagerCounterUpdatersContextToken"
) as InjectionToken<CartManagerCounterUpdatersContext>;
